<template>
  <TransitionRoot appear :show="props.open" as="template">
    <Dialog
      as="div"
      @close="closeSideMenu()"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
    >
      <div class="fixed inset-0 z-30 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-500"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel
              class="fixed inset-y-0 right-0 max-w-md text-left w-11/12 flex flex-col bg-slate-100 pt-16"
            >
              <ul class="py-1 mb-8 h-full w-full pb-20 overflow-y-auto" id="side-navigation-scroll">
                <li v-for="displayGroup in displayGroups" :key="displayGroup.slug">
                  <a
                    :class="[
                      'text-lg px-2 py-1 block whitespace-nowrap',
                      clickedSlug === displayGroup.slug ? 'bg-gray-300' : '',
                    ]"
                    @click="closeSideMenu"
                    :href="`#${displayGroup.slug}`"
                  >
                    {{ displayGroup.emoji }} &nbsp;&nbsp;
                    {{ displayGroup.name.replace(displayGroup.emoji || '', '').trim() }}
                  </a>
                </li>
              </ul>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

interface Props {
  open: boolean
  clickedSlug: string
  displayGroups: any
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
  clickedSlug: '',
})

function closeSideMenu(event?: any) {
  let item
  if (event) {
    const url = new URL(event.target.href)
    item = url.hash

    event.stopPropagation()
    event.preventDefault()
  }

  const customEvent = new CustomEvent('close-side-menu', {
    detail: {
      item: !item ? false : item,
    },
  })
  window.dispatchEvent(customEvent)
}

let xDown = null
let yDown = null

function getTouches(evt) {
  return evt.touches || evt.originalEvent.touches
}

function handleTouchStart(evt) {
  const firstTouch = getTouches(evt)[0]
  xDown = firstTouch.clientX
  yDown = firstTouch.clientY
}

function handleTouchMove(evt) {
  if (!xDown || !yDown) {
    return
  }

  const xUp = evt.touches[0].clientX
  const yUp = evt.touches[0].clientY

  const xDiff = xDown - xUp
  const yDiff = yDown - yUp

  if (Math.abs(xDiff) > Math.abs(yDiff) && xDiff < 0) {
    closeSideMenu()
  }

  xDown = null
  yDown = null
}
</script>
