<script setup lang="ts">
import { display_groups } from '../../data/default.json'
import NavigationDialog from '../components/NavigationDialog.vue'
import { onMounted, ref } from 'vue'

const displayGroups = display_groups.filter((displayGroup) => displayGroup.emoji)
const open = ref(false)
const clickedSlug = ref('')

const openDialog = (event: any) => {
  clickedSlug.value = event.target.id.replace('nav-', '')
  if (clickedSlug.value === 'inicio') {
    return
  }

  event.preventDefault()
  event.stopPropagation()
  open.value = true

  setTimeout(() => {
    const scrollTop = document.querySelector('#side-navigation')?.scrollTop || 0
    document.querySelector('#side-navigation-scroll')?.scrollTo(0, scrollTop)
  }, 50)
}

const closeDialog = (event: any) => {
  open.value = false
  clickedSlug.value = ''

  if (event.detail.item) {
    const currentHash = window.location.hash
    const el = document.querySelector(event.detail.item)
    setTimeout(() => {
      el?.scrollIntoView(true)
    }, 50)
  }
}

onMounted(() => {
  addEventListener('load', () => {
    const navigator = document.querySelector('#side-navigation')
    if (!navigator) return
    function getTouches(evt: any) {
      return evt.touches || evt.originalEvent.touches
    }

    let clientXStart: any
    let clientYStart: any
    navigator.addEventListener('touchstart', function (event) {
      const touches = getTouches(event)
      clientXStart = touches[0].clientX
      clientYStart = touches[0].clientY
    })

    navigator.addEventListener('touchmove', function (event) {
      if (clientXStart && clientYStart) {
        const touches = getTouches(event)
        const diffX = touches[0].clientX - clientXStart
        const diffY = touches[0].clientY - clientYStart
        if (Math.abs(diffX) > Math.abs(diffY)) {
          if (diffX < 0) {
            open.value = true
          }
        }

        clientXStart = clientYStart = undefined
      }
    })
  })

  addEventListener('close-side-menu', closeDialog)
})
</script>

<template>
  <div
    class="flex fixed top-16 bottom-16 w-9 max-w-fit z-30 overflow-x-hidden transition-all pt-px hide-scrollbar"
    id="side-navigation"
  >
    <nav class="relative w-fit h-full bg-slate-100">
      <div class="h-fit bg-slate-100 w-fit">
        <ul class="flex flex-col py-1 mb-8 h-full w-9 pb-12">
          <li v-for="displayGroup in displayGroups" :key="displayGroup.slug">
            <a
              class="text-lg px-2 py-1 block whitespace-nowrap select-none"
              draggable="false"
              :href="`#${displayGroup.slug}`"
              @click="openDialog"
              :id="`nav-${displayGroup.slug}`"
            >
              {{ displayGroup.emoji }}
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <NavigationDialog :open="open" :clicked-slug="clickedSlug" :display-groups="displayGroups" />
  </div>

  <div class="w-9 h-full flex-none"></div>
</template>
